.available-rotors {
  display: flex;
  flex-wrap: wrap;
  margin: 6px;
  justify-content: center;
  margin-bottom: 16px;
}

.line-on-side {
  border: 0.5px solid rgb(212, 212, 212);
  padding: 5px 0px 0px 0px;
  margin: 0 auto;
}

.setting-title {
  font-family: "Ubuntu Mono", monospace;
  color: #555;
  font-size: 0.7rem;
  font-weight: bolder;
}

.settings {
  margin-top: 4px;
}

.enigma-type {
  font-family: "Ubuntu Mono";
  font-size: 15px;
  margin-top: 6px;
}

.settings-are-locked {
  font-family: "Ubuntu Mono", monospace;
  color: #666;
  text-align: center;
  font-weight: 600;
}

.reset-settings {
  margin-top: 0.6rem;
  text-align: center;
  font-family: "Ubuntu Mono";
  font-size: 14px;
}

.clear-logs {
  margin-top: 0.6rem;
  font-family: "Ubuntu Mono";
  font-size: 14px;
  width: 100%;
  display: inline;
}

.clear-logs a {
  color: #999;
  text-decoration: underline;
  float: right;
}

.reset-settings a {
  color: #999;
  text-decoration: underline;
}

.reset-settings a:hover {
  color: #777;
}

.react-select__control {
  border-radius: 0;
}

.settings-are-locked .custom-checkbox .custom-control-label::before {
  box-shadow: none;
  border-radius: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2b303b;
  box-shadow: none;
  border: none;
}

.settings-are-locked
  .custom-checkbox
  .custom-control-input
  ~ .custom-control-indicator {
  background-color: grey !important;
}

.unload-button {
  color: #333;
  padding: 0px 4px 0px 4px;
  height: 100%;
  text-align: center;
  line-height: normal;
  float: right;
  font-family: monospace;
  border: 0 solid gray;
  background-color: #fff;
}

.unload-button:hover {
  background-color: lightgrey;
}

.position {
  margin-top: 10px;
  width: 100%;
  margin-right: 5px;
  color: #333;
  padding: 0.6rem;
  text-align: center;
  font-size: 1rem;
  line-height: normal;
  float: left;
  font-family: "Ubuntu Mono";
  border: 1.5px dashed gray;
}

.position-loaded {
  margin-top: 10px;
  width: 100%;
  margin-right: 5px;
  color: #333;
  padding: 0.6rem;
  text-align: center;
  font-size: 1rem;
  line-height: normal;
  float: left;
  font-family: "Ubuntu Mono";
  border: 1.5px solid gray;
}

.draggable-rotor {
  border: 1px solid #333;
  background-color: white;
  padding: 5px 12px 5px 12px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: move;
  text-align: center;
  font-family: "Ubuntu Mono";
  display: block;
}

.rotor-loaded {
  visibility: hidden;
}
