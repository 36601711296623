@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono&display=swap);
.info {
  font-family: "Ubuntu Mono", monospace;
  color: #555;
  font-size: 0.7rem;
  font-weight: bolder;
  position: absolute;
  top: -13px;
  margin-bottom: 10px !important;
  background-color: #fafafa;
  padding: 0px 5px 0px 5px;
}

span {
  font-family: "Ubuntu Mono", monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  margin-top: 10px !important;
}

.btn-primary {
  background-color: #2b303b;
}

.col-md-4 {
  background-color: #fafafa;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

h5 {
  font-family: "Ubuntu Mono";
  color: white;
  background-color: #2b303b;
  padding: 3px;
  display: inline-block;
}

.title {
  font-family: "Ubuntu Mono";
  color: white;
  background-color: #2b303b;
  padding: 0px 3px 0px 3px;
  display: inline-block;
  width: 120px;
  text-align: center;
  max-width: 150px;
}

.m4-note {
  width: 100%;
  background-color: #2b303b;
  color: #fff;
  padding: 10px;
  font-family: "Ubuntu Mono";
  text-align: center;
}

.note {
  color: darkred;
  margin-left: 10px;
  text-decoration: underline;
}

.keyboard {
  margin-bottom: 3em;
}

.keyboard button {
  border-radius: 0;
}

.keyboard button {
  width: 50px;
  height: 50px;
  margin-right: 5px;
  background-color: #fff;
  color: black;
  border: solid black;
  margin-bottom: 5px;
}

.keyboard button:hover {
  border: solid black;
  background-color: rgb(235, 235, 235);
  color: black;
}

.first-row-letters {
  margin: 0 auto;
  width: 70%;
  margin-bottom: 10px;
  margin-top: 1.9rem;
}

.second-row-letters {
  margin: auto;
  width: 65%;
  margin-bottom: 10px;
}

.third-row-letters {
  margin: auto;
  width: 70%;
}

.gray {
  color: #000;
  padding: 2px;
}

.note {
  color: #474747;
}

.info-panel {
  background-color: #eeeeee;
  width: 100%;
  margin-top: 15px;
  padding: 10px 10px 10px 10px;
  border-radius: 1%;
}

/* PLUGBOARD */

div.oval {
  border-radius: 50px/50px;
  border: solid 2px black;
  width: 15px;
  height: 35px;
  margin: auto;
}

div.clicked {
  background-color: #2b303b;
  border: none;
}

.plug {
  display: inline-block;
  margin: 20px 18px 20px 18px;
  text-align: center;
}

.plugPointer {
  font-family: "Ubuntu Mono", monospace;
  color: #555;
  font-size: 0.7rem;
  font-weight: bolder;
}

.excess {
  color: #fff;
  background-color: #2b303b;
  display: inline-block;
}

/* LAMPS */

.lamps {
  margin-bottom: 3em;
  margin-top: 4px;
}

.lamps .light {
  color: #fff;
  background-color: #2b303b;
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 15px;
  line-height: 32.5px;
  text-align: center;
  border: solid 2px #2b303b;
  color: black;
  display: inline-block;
  margin: 0.6em;
}

.available-rotors {
  display: flex;
  flex-wrap: wrap;
  margin: 6px;
  justify-content: center;
  margin-bottom: 16px;
}

.line-on-side {
  border: 0.5px solid rgb(212, 212, 212);
  padding: 5px 0px 0px 0px;
  margin: 0 auto;
}

.setting-title {
  font-family: "Ubuntu Mono", monospace;
  color: #555;
  font-size: 0.7rem;
  font-weight: bolder;
}

.settings {
  margin-top: 4px;
}

.enigma-type {
  font-family: "Ubuntu Mono";
  font-size: 15px;
  margin-top: 6px;
}

.settings-are-locked {
  font-family: "Ubuntu Mono", monospace;
  color: #666;
  text-align: center;
  font-weight: 600;
}

.reset-settings {
  margin-top: 0.6rem;
  text-align: center;
  font-family: "Ubuntu Mono";
  font-size: 14px;
}

.clear-logs {
  margin-top: 0.6rem;
  font-family: "Ubuntu Mono";
  font-size: 14px;
  width: 100%;
  display: inline;
}

.clear-logs a {
  color: #999;
  text-decoration: underline;
  float: right;
}

.reset-settings a {
  color: #999;
  text-decoration: underline;
}

.reset-settings a:hover {
  color: #777;
}

.react-select__control {
  border-radius: 0;
}

.settings-are-locked .custom-checkbox .custom-control-label::before {
  box-shadow: none;
  border-radius: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2b303b;
  box-shadow: none;
  border: none;
}

.settings-are-locked
  .custom-checkbox
  .custom-control-input
  ~ .custom-control-indicator {
  background-color: grey !important;
}

.unload-button {
  color: #333;
  padding: 0px 4px 0px 4px;
  height: 100%;
  text-align: center;
  line-height: normal;
  float: right;
  font-family: monospace;
  border: 0 solid gray;
  background-color: #fff;
}

.unload-button:hover {
  background-color: lightgrey;
}

.position {
  margin-top: 10px;
  width: 100%;
  margin-right: 5px;
  color: #333;
  padding: 0.6rem;
  text-align: center;
  font-size: 1rem;
  line-height: normal;
  float: left;
  font-family: "Ubuntu Mono";
  border: 1.5px dashed gray;
}

.position-loaded {
  margin-top: 10px;
  width: 100%;
  margin-right: 5px;
  color: #333;
  padding: 0.6rem;
  text-align: center;
  font-size: 1rem;
  line-height: normal;
  float: left;
  font-family: "Ubuntu Mono";
  border: 1.5px solid gray;
}

.draggable-rotor {
  border: 1px solid #333;
  background-color: white;
  padding: 5px 12px 5px 12px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: move;
  text-align: center;
  font-family: "Ubuntu Mono";
  display: block;
}

.rotor-loaded {
  visibility: hidden;
}

#cipher-log .card {
  border-radius: 0;
  border-color: rgb(235, 235, 235);
  background: rgb(235, 235, 235);
}

